<script lang="ts" setup>
import { ref } from 'vue'
import Tabs from '@cypress-design/vue-tabs'
import '@cypress-design/vue-spinner/style.css'
import Spinner from '@cypress-design/vue-spinner'

defineProps<{
  tabs: { id: string; href: string; label: string; active?: boolean; tag?: string }[]
}>()

const transitioning = ref(false)

function change(args: any) {
  setTimeout(() => {
    // if blog takes more than a half second to refresh show spinner
    setTimeout(() => (transitioning.value = true), 400)
    document.location.href = args.href
  }, 150)
}
</script>

<template>
  <Tabs
    :tabs="tabs.map((t) => ({ ...t, href: t.href, ['aria-controls']: 'blog-post-list-container' }))"
    variant="underline-center"
    @switch="change"
  />
  <div
    v-if="transitioning"
    class="fixed left-0 top-0 z-100 flex h-screen w-screen items-center justify-center bg-white/50"
  >
    <Spinner />
  </div>
</template>
